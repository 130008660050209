<template>
    <CompContent title="居民基础数据库" :search="searchFormData" @on-search="searchBtn" @on-reset="resetBtn">
        <template #operate>
            <Button @click="unmatched = !unmatched">
                <Checkbox v-model="unmatched" @click.stop></Checkbox>
                <span>未匹配房号居民</span>
            </Button>
            <Button v-if="type !== 'street'" @click="diaplayHouse = !diaplayHouse">
                <Checkbox v-model="diaplayHouse" @click.stop></Checkbox>
                <span>显示房号</span>
            </Button>
            <!-- <Button type="primary" icon="md-more" @click="openMoreSearch">高级查询</Button> -->
            <Button type="info" @click="onOpenOperationRecord">操作记录</Button>
            <Button v-if="type !== 'street'" type="info" @click="customreport">定制功能或报表</Button>
            <Button v-if="type !== 'street'" type="primary" icon="ios-add" @click="openAddBase">新增居民</Button>

            <Drawer title="高级查询" :closable="false" v-model="searchStatus" width="400">
                <Form>
                    <FormItem label="按姓名，手机号搜索">
                        <Input v-model.trim="searchData.keys" placeholder="请输入姓名或手机号"></Input>
                    </FormItem>
                    <FormItem label="身份类型">
                        <Select v-model="searchData.userType" placeholder="请选择身份类型">
                            <Option value="0">全部</Option>
                            <Option value="4">租赁人员</Option>
                            <Option value="3">是业主且户籍在此</Option>
                            <Option value="2">是业主但户籍不在此</Option>
                            <Option value="1">仅户籍在此</Option>
                            <Option value="5">其他</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="详情地址">
                        <Input v-model.trim="searchData.houseAddr"></Input>
                    </FormItem>
                    <FormItem label="是否家庭户代表">
                        <Select v-model="searchData.contact" placeholder="请选择是否家庭联络人">
                            <Option value="0">全部</Option>
                            <Option value="1">是</Option>
                            <Option value="2">否</Option>
                        </Select>
                    </FormItem>
                </Form>
                <Form :label-width="120">
                    <FormItem :label="item.name" v-for="item in searchRadio" :key="item.name">
                        <RadioGroup :v-model="item.key">
                            <Radio label="">不限</Radio>
                            <Radio label="0">是</Radio>
                            <Radio label="1">否</Radio>
                        </RadioGroup>
                    </FormItem>
                </Form>
                <div class="demo-drawer-footer">
                    <Button type="primary" style="margin-right: 8px" @click="objSearch">搜索</Button>
                    <Button type="success" style="margin-right: 8px" @click="ObjresetBtn">重置</Button>
                    <Button type="info" @click="searchStatus = false">关闭</Button>
                </div>
            </Drawer>
        </template>
        <template #search="evt">
            <Select placeholder="激活状态" v-model="evt.search.activeStatus">
                <Option value="1">未激活</Option>
                <Option value="2">已激活</Option>
            </Select>
            <Select placeholder="数据来源" v-model="evt.search.dataSource">
                <Option value="1">定制功能报表的导入</Option>
                <Option value="2">后台录入</Option>
                <Option value="3">网上家园申请</Option>
                <Option value="4">网上家园填写家庭成员</Option>
                <Option value="5">实名认证申请</Option>
                <Option value="6">底册匹配房号</Option>
                <Option value="7">居民名册初始化</Option>
                <Option value="8">自定义表单同步</Option>
            </Select>
            <Input type="text" placeholder="请输入姓名" v-model="evt.search.userName" style="width: 250px;margin: 5px 10px 5px 0"></Input>
        </template>
        <template>
            <div class="container-box" :class="{ 'display-house': diaplayHouse }">
                <div style="width: 320px" class="left" v-show="diaplayHouse">
                    <LiefengCascaderTree ref="cascader_tree" class="cascader-tree" @handleData="onChangeRoom" @handleOrg="onChangeOrgCode" :value="orgCodeValue" :isShow="!!orgCodeValue"></LiefengCascaderTree>
                </div>
                <LiefengTable
                    class="liefeng-table"
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    :backgroundText="watermark"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </div>

            <CompModal ref="add_model" title="新增居民基础数据" width="400px" @on-close="onCloseAddModel">
                <Baseform ref="add_from" :code="orgCode" @on-submit="saveAddBase"></Baseform>
            </CompModal>

            <!-- 详情 -->
            <Detailsfrom ref="details_from" :modifiable="details.status !== '已归档'" :code="orgCode" @on-refresh="getList"></Detailsfrom>

            <Modal v-model="isDisplayMoveOutUser" title="请选择迁出原因" @on-ok="onSubmitMoveOutUser">
                <span style="padding-left:20px">迁出原因：</span>
                <Select v-model="moveOutDesc" style="width:300px;padding: 20px 10px">
                    <Option value="婚姻原因">婚姻原因</Option>
                    <Option value="工作原因">工作原因</Option>
                    <Option value="学习原因">学习原因</Option>
                    <Option value="房产变更">房产变更</Option>
                    <Option value="出国">出国</Option>
                    <Option value="租期已满">租期已满</Option>
                    <Option value="迁移户口">迁移户口</Option>
                    <Option value="其他">其他</Option>
                </Select>
            </Modal>

            <Modal v-model="isDisplayArchiveUser" title="请选择归档原因" @on-ok="onSubmitArchiveUser">
                <span style="padding-left:20px">归档原因：</span>
                <Select v-model="archiveDesc" style="width:300px;padding: 20px 10px">
                    <Option value="离世">离世</Option>
                    <Option value="其他">其他</Option>
                </Select>
            </Modal>

            <Modal v-model="isDisplayIdNumOccupy" title="身份证号码已存在" ok-text="修改" @on-ok="onJumpEditIdNumOccupy">
                <p style="padding: 16px">当前身份证已被使用，请确认信息是否准确，如无误，请先修改占用身份证的用户信息。</p>
            </Modal>
            <!-- 操作记录 -->
            <OperationRecord ref="operation_record"></OperationRecord>
        </template>
    </CompContent>
</template>

<script>
import LiefengCascaderTree from "@/components/LiefengCascaderTree"
import CompContent from "../customform/components/CompContent.vue"
import LiefengTable from "@/components/LiefengTable"
import Baseform from "./children/baseform"
import Detailsfrom from "./children/DetailsFroms.vue"
import OperationRecord from "./children/OperationRecord.vue"
import CompModal from "./components/CompModal.vue"
import Utils from "../customform/utils/utils"
import CanvasUtils from "./utils/canvas_utils"
import ElemLook from "./components/ElemLook.vue"
import Axios from "axios"
import Route from "../communitymobilization/entity/Route"
import Street from "../managsystem/utils/street"

export default {
    components: {
        CompContent,
        LiefengTable,
        Baseform,
        Detailsfrom,
        LiefengCascaderTree,
        OperationRecord,
        CompModal,
    },
    data() {
        return {
            Route: Route,

            searchFormData: {
                activeStatus: null,
            },

            search: {
                userName: "",
            },

            searchData: {
                // danyuan: "",
                houseAddr: "",
            },
            searchRadio: [
                {
                    name: "党员",
                    label: "",
                    key: "searchData.danyuan",
                },
                {
                    name: "志愿者",
                    label: "",
                    key: "searchData.danyuan",
                },
                {
                    name: "居民小组",
                    label: "",
                    key: "searchData.danyuan",
                },
                {
                    name: "有进行健康评估",
                    label: "",
                    key: "searchData.danyuan",
                },
                {
                    name: "困难群体",
                    label: "",
                    key: "searchData.danyuan",
                },
                {
                    name: "享受低保",
                    label: "",
                    key: "searchData.danyuan",
                },
                {
                    name: "孤独老人",
                    label: "",
                    key: "searchData.danyuan",
                },
                {
                    name: "残疾人士",
                    label: "",
                    key: "searchData.danyuan",
                },
                {
                    name: "退役军人",
                    label: "",
                    key: "searchData.danyuan",
                },
            ],
            searchStatus: false,

            tableData: [],
            talbeColumns: [
                {
                    title: "序号",
                    type: "index",
                    minWidth: 80,
                    align: "center",
                    fixed: "left",
                },
                {
                    title: "房号",
                    key: "houseNum",
                    minWidth: 120,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "userName",
                    minWidth: 120,
                    align: "center",
                },
                {
                    title: "联系电话",
                    key: "mobile",
                    minWidth: 150,
                    align: "center",
                },
                {
                    title: "证件号码",
                    key: "idNum",
                    minWidth: 200,
                    align: "center",
                },
                {
                    title: "所在详细区域",
                    key: "addr",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "是否激活",
                    key: "activeStatus",
                    minWidth: 120,
                    align: "center",
                },
                {
                    title: "来源",
                    key: "dataSource",
                    minWidth: 170,
                    align: "center",
                },
                {
                    title: "数据状态",
                    key: "status",
                    minWidth: 120,
                    align: "center",
                },
                {
                    fixed: "right",
                    title: "操作",
                    width: 250,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(ElemLook, {
                                style: "margin-right:8px",
                                props: {
                                    row: params.row,
                                    id: params.row.ubaseId,
                                    source: "ubase",
                                },
                                on: {
                                    "on-change": res => {
                                        Object.assign(params.row, res.value)
                                    },
                                },
                            }),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "success",
                                        size: "small",
                                    },
                                    style: "margin:0 3px",
                                    nativeOn: {
                                        click: () => {
                                            this.details = params.row
                                            this.$refs.details_from.display(params.row.ubaseId)
                                        },
                                    },
                                },
                                "编辑"
                            ),
                            h(
                                "Dropdown",
                                {
                                    props: {
                                        transfer: true,
                                    },
                                    style: "margin:0 3px",
                                },
                                [
                                    h(
                                        "Button",
                                        {
                                            props: {
                                                type: "info",
                                                size: "small",
                                                icon: "ios-arrow-down",
                                            },
                                        },
                                        "更多"
                                    ),
                                    h(
                                        "DropdownMenu",
                                        {
                                            slot: "list",
                                        },
                                        [
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.deleteUser(params.row.ubaseId)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "删除"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.$refs.operation_record.display(params.row.ubaseId)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                "查看修改记录"
                                            ),
                                            params.row.status === "已归档"
                                                ? null
                                                : h(
                                                      "DropdownItem",
                                                      {
                                                          nativeOn: {
                                                              click: () => {
                                                                  if (params.row.status === "已迁出") {
                                                                      this.onMoveInUser(params.row.ubaseId)
                                                                  } else {
                                                                      this.moveOutUbaseId = params.row.ubaseId
                                                                      this.isDisplayMoveOutUser = true
                                                                  }
                                                              },
                                                          },
                                                          style: {
                                                              textAlign: "center",
                                                          },
                                                      },
                                                      params.row.status === "已迁出" ? "迁入" : "迁出"
                                                  ),
                                            params.row.status === "已归档"
                                                ? null
                                                : h(
                                                      "DropdownItem",
                                                      {
                                                          nativeOn: {
                                                              click: () => {
                                                                  this.archiveUbaseId = params.row.ubaseId
                                                                  this.isDisplayArchiveUser = true
                                                              },
                                                          },
                                                          style: {
                                                              textAlign: "center",
                                                          },
                                                      },
                                                      "归档"
                                                  ),
                                        ]
                                    ),
                                ]
                            ),
                        ])
                    },
                },
            ],
            loading: false,
            pageSize: 20,
            total: 0,
            page: 1,

            //详情
            details: {},

            orgCode: null,

            // 迁出原因
            moveOutDesc: null,
            // 显示迁出原因
            isDisplayMoveOutUser: false,
            // 归档原因
            archiveDesc: null,
            // 显示迁出原因
            isDisplayArchiveUser: false,
            // 未匹配到房号
            unmatched: false,
            // 显示房号
            diaplayHouse: localStorage.getItem("RESIDENT_DATABASE_IS_HOUSE") === "true",
            // 初始化默认社区
            orgCodeValue: sessionStorage.getItem("RESIDENT_DATABASE_ORGCODE") || null,
            // 水印
            watermark: null,
            // 数据类型
            type: this.$core.getUrlParam("type"),
        }
    },

    watch: {
        unmatched() {
            this.page = 1
            this.getList()
        },

        isDisplayMoveOutUser(v) {
            !v &&
                this.$nextTick(() => {
                    this.moveOutDesc = null
                })
        },

        isDisplayArchiveUser(v) {
            !v &&
                this.$nextTick(() => {
                    this.archiveDesc = null
                })
        },

        diaplayHouse(v) {
            localStorage.setItem("RESIDENT_DATABASE_IS_HOUSE", `${v}`)
        },
    },

    created() {
        this.loading = true

        // 是否需要显示已激活用户
        if (this.$core.getUrlParam("active")) {
            this.searchFormData.activeStatus = "2"
            this.search = this.searchFormData
        }

        if (this.type === "street") {
            this.getList()
        }
    },

    mounted() {
        // 新建一个 Canvas 工具类，外边距 10 px
        const canvas = new CanvasUtils(10)
        // 获取用户信息
        const userinfo = parent.vue.loginInfo.userinfo
        // 逆时针旋转 30 度
        canvas.rotate(-30)
        // 绘制登录人信息 & 当前时间
        canvas.draw(`${userinfo.realName} ${userinfo.mobile.length >= 4 ? userinfo.mobile.substring(userinfo.mobile.length - 4, userinfo.mobile.length) : ""}`, this.$core.formatDate(new Date(), "MM-dd hh:mm"))
        // 获取 base64 图片
        this.watermark = canvas.toImage()
        // 销毁
        canvas.destroy()
    },

    methods: {
        /**
         * 切换房号
         */
        onChangeRoom(evt) {
            const param = {}

            if (evt.orgLevel === "6") {
                param.roadCode = evt.regionCode
            } else if (evt.regionType === "1") {
                param.projectCode = evt.regionCode
            } else if (evt.regionType === "2") {
                param.buildingCode = evt.regionCode
            } else if (evt.regionType === "3") {
                param.buildingCode = evt.regionCode
            } else if (evt.regionType === "4" || evt.regionType === "0") {
                param.houseNo = evt.regionCode
            }

            this.reqParam = param

            // 获取分页数据
            this.getList()
        },

        onChangeOrgCode(evt) {
            this.orgObj = evt
            this.orgCode = evt.orgCode
            // 获取分页数据
            this.getList()
        },

        customreport() {
            if (!this.orgObj) {
                return this.$Message.warning({
                    content: "数据加载中",
                    background: true,
                })
            }

            let orgObj = this.orgObj

            Route.jump(`/residentdatabasemybusiness?orgCode=${orgObj.orgCode}&orgName=${orgObj.orgName}`)
        },

        // 分页
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },

        // 搜索
        searchBtn(evt) {
            this.page = 1
            this.pageSize = 20
            this.search = evt.value
            this.getList()
        },

        // 重置
        resetBtn() {
            this.page = 1
            this.pageSize = 20
            this.search = {}
            this.getList()
        },

        // 高级查询
        openMoreSearch() {
            this.searchStatus = true
        },

        objSearch() {
            this.search = { ...this.search, ...this.searchData }
            this.searchStatus = false
            this.getList()
        },
        ObjresetBtn() {
            this.searchData = {}
        },
        // 获取数据
        async getList() {
            this.loading = true

            const param = {
                page: this.page,
                pageSize: this.pageSize,
                hasHouseNum: !this.unmatched,
            }

            if (this.type === "street") {
                param.streetCode = (await Street.getInfo()).orgCode
                param.appId = this.$core.getUrlParam("appId")
            } else {
                param.communityCode = this.orgObj.orgCode
            }

            if (this.reqParam) {
                Object.assign(param, this.reqParam)
            }

            param.dataSource = this.search?.dataSource
            param.activeStatus = this.search?.activeStatus

            Axios({
                method: "post",
                url: `/syaa/api/syuser/pc/${this.type === "street" ? "streetUser" : "uaUserInfo"}/selectUserBasePage${Utils.jsonToParams(param)}`,
                data: this.getCondition(),
            })
                .then(res => {
                    this.loading = false
                    const data = res.data

                    if (data.code == 200 && data.dataList) {
                        this.tableData = data.dataList
                        this.tableData.map(item => {
                            item.updateStatus = 2
                        })
                        this.total = data.maxCount
                    } else {
                        this.$Message.error({
                            content: data.desc || data.msg,
                            background: true,
                        })
                    }
                })
                .catch(() => {
                    this.$Message.error({
                        content: "数据获取失败",
                        background: true,
                    })
                })
        },

        /**
         * 获取查询条件
         */
        getCondition() {
            const res = []
            const sd = this.search

            for (let i = 0, keys = Object.keys(sd); i < keys.length; i++) {
                let k = keys[i]

                // 判空
                if (!sd[k] || ["activeStatus", "dataSource"].includes(k)) continue

                res.push({
                    queryFieldId: k,
                    queryFieldValue: sd[k],
                    searchMethod: "Contains",
                })
            }

            return res
        },

        //删除
        deleteUser(id) {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>删除后将看不到该数据，请谨慎操作，是否确认删除？</p>",
                onOk: () => {
                    this.$post("/syaa/api/syuser/pc/uaUserInfo/deleteUserById", {
                        ubaseId: id,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    content: "删除成功",
                                    background: true,
                                })
                                // 刷新列表
                                this.getList()
                            } else {
                                this.$Message.error({
                                    content: res.desc,
                                    background: true,
                                })
                            }
                        })
                        .catch(() => {
                            this.$Message.error({
                                content: "删除失败",
                                background: true,
                            })
                        })
                },
            })
        },

        /**
         * 用户迁入
         */
        onMoveInUser(id) {
            this.$post("/syaa/api/syuser/pc/uaUserInfo/joinUserById", {
                ubaseId: id,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "迁入成功",
                            background: true,
                        })
                        // 刷新列表
                        this.getList()
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
                .catch(() => {
                    this.$Message.error({
                        content: "迁入失败",
                        background: true,
                    })
                })
        },

        /**
         * 用户迁出
         */
        onSubmitMoveOutUser() {
            if (!this.moveOutDesc) {
                return this.$Message.error({
                    content: "请选择迁出原因",
                })
            }

            this.$post("/syaa/api/syuser/pc/uaUserInfo/moveOutUserById", {
                ubaseId: this.moveOutUbaseId,
                operateDesc: this.moveOutDesc,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "迁出成功",
                            background: true,
                        })
                        // 刷新列表
                        this.getList()
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
                .catch(() => {
                    this.$Message.error({
                        content: "迁出失败",
                        background: true,
                    })
                })
        },

        /**
         * 用户归档
         */
        onSubmitArchiveUser() {
            if (!this.archiveDesc) {
                return this.$Message.error({
                    content: "请选择归档原因",
                })
            }

            this.$post("/syaa/api/syuser/pc/uaUserInfo/archiveUserById", {
                ubaseId: this.archiveUbaseId,
                operateDesc: this.archiveDesc,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "归档成功",
                            background: true,
                        })
                        // 刷新列表
                        this.getList()
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
                .catch(() => {
                    this.$Message.error({
                        content: "归档失败",
                        background: true,
                    })
                })
        },

        // 打开新增基础数据
        openAddBase() {
            this.$refs.add_model.display()
        },

        // 保存基础数据新增
        saveAddBase(evt) {
            const baseData = evt.value

            if (!baseData.userName) {
                return this.$Message.warning({
                    content: "请输入姓名",
                    background: true,
                })
            } else if (!baseData.addr) {
                return this.$Message.warning({
                    content: "请选择地址",
                    background: true,
                })
            } else if (baseData.idNumType == 1 && baseData.idNum && !Utils.verify("idNum", baseData.idNum)) {
                return this.$Message.warning({
                    content: "证件号码格式不正确",
                    background: true,
                })
            }

            this.$post("/syaa/api/syuser/pc/uaUserInfo/addUserBase", {
                ...baseData,
                communityCode: this.orgObj.orgCode,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "新增成功",
                            background: true,
                        })
                        this.getList()
                        this.$refs.add_model.close()
                    }
                    // 身份证号重复，提示修改
                    else if (res.code == "IDNUM_EXIST") {
                        this.idNumOccupy = baseData.idNum
                        this.isDisplayIdNumOccupy = true
                    } else {
                        this.$Message.error({
                            content: res.desc,
                            background: true,
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.$Message.error({
                        content: "新增失败",
                        background: true,
                    })
                })
        },

        onCloseAddModel() {
            // 重置表单数据
            this.$refs.add_from.reset()
        },

        /**
         * 关闭详情窗口
         */
        onCloseDetailsModel() {
            // 刷新列表
            this.getList()
            // 重置表单数据
            this.$refs.details_from.reset()
        },

        processStructure(fps) {
            for (let i = 0; i < fps.length; i++) {
                const fp = fps[i]
                const value = {}

                for (let fi = 0, fos = fp.formOperates; fi < fos.length; fi++) {
                    const fo = fos[fi]

                    // 忽略详细地址组件，合并在 ElemAddr 组件
                    if (fo.fieldType === "hidden") {
                        fos.splice(fi--, 1)
                        // 赋值
                        value[fo.fieldId] = fo.fieldValue
                        continue
                    }

                    fos[fi] = {
                        ...fo,
                        type: fo.fieldType,
                        name: fo.fieldId,
                        title: fo.fieldName,
                        max: fo.limitMaxLen,
                        verify: fo.validate?.inputValueType,
                    }

                    // 处理 checkbox 类型规则，使用分割线分隔
                    if (fo.fieldType === "checkbox" && fo.fieldValue) {
                        if (typeof fo.fieldValue === "string" && fo.fieldValue?.indexOf("|")) {
                            fo.fieldValue = fo.fieldValue.split("|")
                        }
                    }

                    value[fo.fieldId] = fo.fieldValue
                }

                fp.formValue = value
            }

            return fps
        },

        onOpenOperationRecord() {
            this.$refs.operation_record.display()
        },

        onBack() {
            Route.back()
        },
    },
}
</script>

<style lang="less" scope>
.form-box {
    position: relative;
    z-index: 100;
}

#contentarea {
    overflow: hidden !important;
}

.dotuClass {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bread {
    background-color: rgba(0, 0, 0, 0);
}

/deep/ .ivu-tree-title-selected {
    background: #00ffff;
}

/deep/ .ivu-tree-title:hover {
    background: #f08080;
}

/deep/ .ivu-breadcrumb {
    padding: 0;
    margin: 0;
}

/deep/ .ivu-modal-body {
    padding-bottom: 1px;
}

.formStyle {
    text-align: right;
}

.toggle1 {
    color: red;
    display: inline-block;
}

.container-box {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 85px);
    width: calc(100vw - 20px);

    > div:nth-child(2) {
        width: calc(100vw - 25px);
    }

    &.display-house {
        > div:nth-child(2) {
            width: calc(100vw - 345px);
        }
    }

    .left {
        position: relative;
        z-index: 10;
    }

    /deep/ .left {
        margin: 0 10px;
        width: 320px;
        height: 100%;

        /deep/ .ivu-select-dropdown {
            height: calc(100vh - 160px);
            max-height: calc(100vh - 160px);
        }
    }

    /deep/ .ivu-tree {
        padding: 0 6px;
        border-left: 1px solid #dcdee2;
        border-right: 1px solid #dcdee2;
        border-bottom: 1px solid #dcdee2;
        height: calc(100vh - 177px);
        overflow: auto;
    }
}
</style>
